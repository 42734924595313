
$(document).ready(function () {

  /* Post automatique du moteur de recherche à la sélection d'un item dans une lite */
  $('#moteur-recherche-form .autopost').change(function () {
    $("#moteur-recherche-form").submit();
  });

  /* Géoloc */
  // Si je veux déclencher moi même l'ouverture, j'ai une erreur "modal is not a function", alors que si je le fais depuis la console, ça fonctionne
  $("#geoloc-button").on("click", function () {
    $("#geolocModal").modal();
  });

  $("#geolocModal").on('shown.bs.modal', function (event) {
    console.log('ouverture modale');
    $("#update-geoloc-input").focus();
  });

  $('#update-geoloc-input').on("paste keyup", function () {
    console.log("je tape un truc : " + $('#update-geoloc-input').val());

    $.post("/Occasions/Geoloc/SearchByTerm", { term: $('#update-geoloc-input').val() }).done(function (data) {
      $('#update-geoloc-results').html(data);

      $('.geoloc-searchresult-item').on("click", function () {
        console.log("clic sur un resultat");
        console.log(this);
        //SelectGeolocPoint($(this).data("lat"), $(this).data('lng'), $(this).data('cp'), $(this).data('commune'));
        SelectGeolocPointForNetCore($(this).data("lat"), $(this).data('lng'), $(this).data('cp'), $(this).data('commune'));
        //console.log($(this).data("commune"));
      })
    });/* fin post */
  });

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  });

});


/* Ne fonctionne pas, .net Core ne veut pas de json dans un cookie. Je vais être obligé de générer une chaine avec des valeurs "lisibles" par .net core et les décoder côté controller */
export function SelectGeolocPoint(lat, lng, zip, name) {
  console.log("SelectGeolocPoint");
  /*
  var cookieGeoloc = JSON.parse(readCookie("geolocation"));
  if (cookieGeoloc) {
    cookieGeoloc.Lat = lat;
    cookieGeoloc.Lng = lng;
    cookieGeoloc.Commune = name;
    cookieGeoloc.CodePostal = zip;
  }
  else
      cookieGeoloc = { Lat: lat, Lng: lng, Commune: name, CodePostal: zip};
  */
  var cookieGeoloc = { Lat: lat, Lng: lng, Commune: name, CodePostal: zip };
  /* on pose le cookie */
  createCookie("geolocation", '"' + JSON.stringify(cookieGeoloc) + '"', 1);
  /* on recharge la apge */
  location.reload();
}


function SelectGeolocPointForNetCore(lat, lng, zip, name) {
  var cookieValue = lat + '#' + lng + "#" + zip + "#" + name;
  createCookie("geolocation", encodeURIComponent(cookieValue), 365);
  location.reload();
}

function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
  }
  else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}



function readCookie(name) {
  var nameequa = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameequa) == 0) return c.substring(nameequa.length, c.length);
  }
  return null;
}

export function coucou() {
  alert("coucou");
}


