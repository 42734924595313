import './scss/main';
import './js/listeannonces';
import './js/annonce';

import 'popper.js';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tooltip';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import {coucou} from './js/listeannonces';
//import { SelectGeolocPoint } from './js/listeannonces';



Carousel();
CarouseilRemonteColDroite();

function Carousel() {

  console.log("Js Occasion - Carousel");

  if ($('.owl-carousel:not(.owl-carousel-custom)')[0]) {
    $('.owl-carousel:not(.owl-carousel-custom)').owlCarousel({
      dots: false,
      nav: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
          items: 3,
          nav: true,
          loop: false
        }
      }
    });
  }
}

function CarouseilRemonteColDroite() {
  $('.owl-carousel-annonce-coldroite').owlCarousel({
    dots: true,
    nav: true,
    responsiveClass: true,
    items: 1,
    loop: true,
    autoplay : true
    //responsive: {
    //  0: {
    //    items: 1,
    //    nav: true
    //  },
    //  600: {
    //    items: 2,
    //    nav: false
    //  },
    //  1000: {
    //    items: 3,
    //    nav: true,
    //    loop: false
    //  }
    //}
  });
}